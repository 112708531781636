<template>
  <div class="home">
    <h4>添加资源</h4>
    <div class="my-3">
      <form action="">
        <div class="mb-3 row">
          <label for="name" class="col-sm-2 col-form-label">姓名</label>
          <div class="col-sm-10">
            <input type="text" name="name" class="form-control" id="name">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="phone" class="col-sm-2 col-form-label">电话</label>
          <div class="col-sm-10">
            <input type="text" name="phone" class="form-control" id="phone">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputWechat" class="col-sm-2 col-form-label">微信</label>
          <div class="col-sm-10">
            <input type="text" name="wechat" class="form-control" id="inputWechat">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputGender" class="col-sm-2 col-form-label">性别</label>
          <div class="col-sm-10">
            <select class="form-select" name="gender" id="inputGender">
              <option selected>请选择性别</option>
              <option value="1">男</option>
              <option value="2">女</option>
            </select>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputAge" class="col-sm-2 col-form-label">年龄</label>
          <div class="col-sm-10">
            <input type="text" name="age" class="form-control" id="inputAge">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputIncome" class="col-sm-2 col-form-label">收入</label>
          <div class="col-sm-10">
            <input type="text" name="text" class="form-control" id="inputIncome">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputBody" class="col-sm-2 col-form-label">备注</label>
          <div class="col-sm-10">
            <textarea class="form-control" name="body" id="inputBody"></textarea>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputSource" class="col-sm-2 col-form-label">来源</label>
          <div class="col-sm-10">
            <select class="form-select" name="source" id="inputSource">
              <option selected>请选择来源</option>
              <option value="1">口碑</option>
              <option value="2">今日头条</option>
              <option value="3">大众点评</option>
            </select>
          </div>
        </div>
        <button type="submit" class="btn btn-primary">保存</button>
      </form>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src
// import $ from 'jquery';


export default {
  name: 'Edit',

}
</script>